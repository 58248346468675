import React, { FC } from 'react';
import { Typography, Box, Chip, Checkbox, styled } from '@mui/material';
import { useUser } from '../../contexts/UserContext';
import UserAvatar from '../UserAvatar';

// Styled component for underlined text
const UnderlinedText = styled('span')(({ theme }) => ({
  position: 'relative',
  display: 'inline-block',
  '&::after': {
    content: '""',
    position: 'absolute',
    left: 0,
    bottom: -2,
    width: '100%',
    height: '4px',
    backgroundColor: theme.palette.primary.main,
    borderRadius: '2px',
  },
}));

const UserProfilePage: FC = () => {
  const { user } = useUser();

  // If user is null, show a fallback message

  return (
    <Box display="flex" justifyContent="center" alignItems="center" px={2}>
      {!user ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '2rem',
            borderRadius: '8px',
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
            backgroundColor: '#ffffff',
            maxWidth: '600px',
            margin: 'auto',
          }}
        >
          <Typography variant="h6" color="textSecondary">
            No user data available.
          </Typography>
        </Box>
      ) : (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '2rem',
            borderRadius: '8px',
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
            backgroundColor: '#ffffff',
            maxWidth: '600px',
            margin: 'auto',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              gap: '16px',
              alignItems: 'center',
              marginBottom: '3rem',
            }}
          >
            <UserAvatar user={user} style={{ width: 100, height: 100 }} />
            <Typography variant="h5" component="div" gutterBottom>
              <UnderlinedText>
                {user.firstName} {user.lastName}
              </UnderlinedText>
            </Typography>
          </Box>

          <Box
            sx={{
              display: 'grid',
              gridTemplateColumns: '150px auto',
              gap: '8px',
              width: '100%',
              maxWidth: '600px',
              marginTop: '16px',
              justifyContent: 'center',
            }}
          >
            <Typography variant="body2" color="textSecondary">
              Email:
            </Typography>
            <Typography variant="body2">{user.mail}</Typography>

            <Typography variant="body2" color="textSecondary">
              Bundesland:
            </Typography>
            <Typography variant="body2">
              {user.province || 'Nicht angegeben'}
            </Typography>

            <Typography variant="body2" color="textSecondary">
              Erlaubte Arbeitszeiten:
            </Typography>
            <Box sx={{ display: 'flex', gap: '8px', flexWrap: 'wrap' }}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '4px',
                  color: 'silver',
                }}
              >
                <Checkbox
                  checked={user.allowedWorkingHours?.nightAllowed ?? false}
                  disabled
                  color="primary"
                />
                <Typography variant="body2">Nachts</Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '4px',
                  color: 'silver',
                }}
              >
                <Checkbox
                  checked={user.allowedWorkingHours?.weekendAllowed ?? false}
                  disabled
                  color="primary"
                />
                <Typography variant="body2">Wochenende</Typography>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: '4px',
                  color: 'silver',
                }}
              >
                <Checkbox
                  checked={user.allowedWorkingHours?.holidayAllowed ?? false}
                  disabled
                  color="primary"
                />
                <Typography variant="body2">Feiertage</Typography>
              </Box>
            </Box>

            <Typography variant="body2" color="textSecondary">
              Maximale Stunden pro Woche:
            </Typography>
            <Typography variant="body2">
              {user.allowedWorkingHours?.weekMax || 0}
            </Typography>

            <Typography variant="body2" color="textSecondary">
              Übertrag Arbeitszeit:
            </Typography>
            <Typography variant="body2">
              {user.pendingMinutes || 0} min
            </Typography>

            <Typography variant="body2" color="textSecondary">
              Status:
            </Typography>
            <Typography variant="body2">{user.status || 'unknown'}</Typography>

            <Typography variant="body2" color="textSecondary">
              Rollen:
            </Typography>
            <Box sx={{ display: 'flex', gap: '8px', flexWrap: 'wrap' }}>
              {user.roles.map((role) => (
                <Chip
                  key={role}
                  label={role}
                  color="primary"
                  style={{
                    backgroundColor: 'green',
                    color: 'white',
                  }}
                />
              ))}
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default UserProfilePage;
