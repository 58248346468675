import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
  useCallback,
} from 'react';
import { useParams } from 'react-router-dom';
import { StatusEnum, TimesheetService, TimesheetSummary } from '../api';
import dayjs, { Dayjs } from 'dayjs';
import {
  shiftDateByOneMonthMinusOneDay,
  computeRows,
  convertRowsToWorkingDays,
  formatDate,
} from '../components/Timesheet/tool';
import { useUser } from './UserContext';
import { useSnackbar } from './SnackbarProvider';

export interface Row {
  date: Dayjs; // The date of the entry
  from: Dayjs | null; // Start work time
  to: Dayjs | null; // End work time
  pause: Dayjs | null; // total break time
  workingHours: number; // total working time this day
  type: string | number; // Urlaub, Abwesend, ... (needs to bis type: string | number for this wierd mui component)
  state: StatusEnum; // Status (e.g., LOCKED, VALID, etc.)
  block: boolean; // Indicates if the row is blocked (for weekends for example)
  holiday: string; // Holiday name (if any)
  info: string;
}

interface TimesheetContextValue {
  timesheetSummary: TimesheetSummary | null;
  rows: Row[];
  isMonthly: boolean;
  setTimesheetEndDate: React.Dispatch<React.SetStateAction<Date | undefined>>;
  setTimesheetStartDate: React.Dispatch<React.SetStateAction<Date | undefined>>;
  updateFrom: (index: number, newVon: Dayjs | null) => void;
  updateTo: (index: number, newBis: Dayjs | null) => void;
  updatePause: (index: number, newPause: Dayjs | null) => void;
  updateNote: (index: number, newNote: string | number) => void;
}

// Create the Timesheet Context
const TimesheetContext = createContext<TimesheetContextValue | undefined>(
  undefined,
);

// Timesheet Provider Props
interface TimesheetProviderProps {
  children: ReactNode;
}

// Timesheet Provider Component
export const TimesheetProvider: React.FC<TimesheetProviderProps> = ({
  children,
}) => {
  const { startDate, endDate, uuid } = useParams<{
    startDate?: string;
    endDate?: string;
    uuid?: string;
  }>();
  const { user } = useUser();
  const { showSnackbar } = useSnackbar();

  //reqeust parameters
  const [timesheetStartDate, setTimesheetStartDate] = useState<
    Date | undefined
  >();
  const [timesheetEndDate, setTimesheetEndDate] = useState<Date | undefined>();
  const [isMonthly, setIsMonthly] = useState(true);

  const [timesheetSummary, setTimeSheetSummary] =
    useState<TimesheetSummary | null>(null);

  //timesheet data
  const [rows, setRows] = useState<Row[]>([]);

  // This tries to get the start date of the timesheet. If no parameter in url is given set timeframe to current month.
  useEffect(() => {
    if (startDate) {
      const date = new Date(startDate);
      setTimesheetStartDate(date);

      if (endDate) setTimesheetEndDate(new Date(endDate));
      // set end date to + 1 month - 1 day
      else setTimesheetEndDate(shiftDateByOneMonthMinusOneDay(date));
      setIsMonthly(false);
    } else {
      const current_date = new Date();

      const firstDayOfMonth = new Date(
        current_date.getFullYear(),
        current_date.getMonth(),
        1,
      );
      setTimesheetStartDate(firstDayOfMonth);

      const lastDayOfMonth = new Date(
        current_date.getFullYear(),
        current_date.getMonth() + 1,
        0,
      );
      setTimesheetEndDate(lastDayOfMonth);
      setIsMonthly(true);
    }
  }, []);

  // Initial load to fetch timesheet data instantly
  useEffect(() => {
    if (timesheetStartDate && timesheetEndDate && (uuid || user?.uuid)) {
      updateTimesheet([]);
    }
  }, [timesheetStartDate, timesheetEndDate, uuid, user]);

  // Function to update the timesheet data
  const updateTimesheet = (changedRows: Row[]) => {
    const finaluuid = uuid || user?.uuid;
    if (finaluuid && timesheetStartDate && timesheetEndDate) {
      const data = convertRowsToWorkingDays(changedRows);
      TimesheetService.updateTimesheet(
        finaluuid,
        formatDate(timesheetStartDate),
        formatDate(timesheetEndDate),
        convertRowsToWorkingDays(changedRows),
      )
        .then((x) => {
          // update timesheet with data returned by backend (might be enriched with errors)
          setTimeSheetSummary(x.summary);
          const a = dayjs(x.summary.startDate);
          const b = dayjs(x.summary.endDate);

          setRows(computeRows(a, b, x.workingDays));
          if (data.length > 0) showSnackbar('Data saved!', 'success');
        })
        .catch((error) => {
          showSnackbar('An error occurred: ', 'error', error);
        });
    }
  };
  const updateFrom = useCallback(
    (index: number, newFrom: Dayjs | null) => {
      const newRow = rows[index];
      updateTimesheet([{ ...newRow, from: newFrom }]);
    },
    [rows, updateTimesheet],
  );

  const updateTo = useCallback(
    (index: number, newTo: Dayjs | null) => {
      const newRow = rows[index];
      updateTimesheet([{ ...newRow, to: newTo }]);
    },
    [rows, updateTimesheet],
  );

  const updatePause = useCallback(
    (index: number, newPause: Dayjs | null) => {
      const newRow = rows[index];
      updateTimesheet([{ ...newRow, pause: newPause }]);
    },
    [rows, updateTimesheet],
  );

  const updateNote = useCallback(
    (index: number, newNote: string | number) => {
      const newRow = rows[index];
      updateTimesheet([{ ...newRow, type: newNote }]);
    },
    [rows, updateTimesheet],
  );

  return (
    <TimesheetContext.Provider
      value={{
        timesheetSummary,
        rows,
        isMonthly,
        setTimesheetEndDate,
        setTimesheetStartDate,
        updateFrom,
        updateTo,
        updatePause,
        updateNote,
      }}
    >
      {children}
    </TimesheetContext.Provider>
  );
};

// Custom hook to use the Timesheet Context
export const useTimesheet = (): TimesheetContextValue => {
  const context = useContext(TimesheetContext);
  if (!context) {
    throw new Error('useTimesheet must be used within a TimesheetProvider');
  }
  return context;
};
