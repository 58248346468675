import React, { FC } from 'react';
const ImprintPage: FC = () => {
  React.useEffect(() => {
    window.location.replace('https://www.nterra.com/impressum');
  }, []);
  // Component as backup
  return (
    <div>
      <span>nterra integration GmbH</span>
      <br />
      <span></span>
      <br />
      <span>HRB Darmstadt 87413</span>
      <br />
      <span>Steuer-Nr.: 07 240 03267</span>
      <br />
      <span>USt-IdNr.: DE213709651</span>
      <br />
      <span>Geschäftsführer: David Brantzen, Heiko Cestonaro</span>
      <br />
      <span></span>
      <br />
      <span>Anschrift & Kontakt</span>
      <br />
      <span></span>
      <br />
      <span>nterra integration GmbH</span>
      <br />
      <span>Im Leuschnerpark 3</span>
      <br />
      <span>64347 Griesheim</span>
      <br />
      <span>Germany</span>
      <br />
      <span></span>
      <br />
      <span>Telefon: +49 6155 60710</span>
      <br />
      <span>Telefax: : +49 6155 607125</span>
      <br />
      <span>E-Mail: info@nterra.com</span>
      <br />
      <span>Homepage: www.nterra.com</span>
      <br />
      <span></span>
      <br />
      <span>
        Inhaltlich Verantwortlicher gemäß § 55 RStV und § 5 TMG: Heiko
        Cestonaro, Executive Partner
      </span>
      <br />
      <span>
        Ansprechpartner für Themen und Inhalte: Paul Adelmann, Martin Stemmer
      </span>
      <br />
    </div>
  );
};

export default ImprintPage;
