import React, { useState, useEffect } from 'react';

const ExtraTextRow = ({
  text,
  color = 'inherit',
  alignTextLeft = true,
}: {
  text: string;
  color?: string;
  alignTextLeft?: boolean;
}) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const textClass = alignTextLeft ? 'information' : 'summary';

  useEffect(() => {
    // Trigger the expansion when the text changes or component mounts
    setIsExpanded(true);
  }, [text]);

  const rowStyle: React.CSSProperties = {
    transition: 'max-height 1.0s ease-out, opacity 1.0s ease-out',
    maxHeight: isExpanded ? '100px' : '0', // Adjust maxHeight as needed
    opacity: isExpanded ? 1 : 0,
    overflow: 'hidden',
  };

  return (
    <tr style={rowStyle}>
      <td
        className={'column-divider datum ' + textClass}
        style={{ textAlign: 'center', width: '20px' }}
      ></td>
      <td colSpan={7} className={textClass} style={{ backgroundColor: color }}>
        {text}
      </td>
    </tr>
  );
};

export default ExtraTextRow;
