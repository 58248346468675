import { Alert } from '@mui/material';
import React from 'react';

const WarningComponent = () => {
  return (
    <Alert
      severity="warning"
      sx={{
        margin: '20px',
        marginBottom: '1rem',
        fontSize: '1.2rem',
        padding: '1rem 2rem',
        borderRadius: '8px',
        backgroundColor: '#FFA000',
        color: '#ffffff',
        display: 'flex',
        alignItems: 'center',
        '& .MuiAlert-icon': {
          fontSize: '2.5rem',
          color: '#ffffff',
          marginRight: '1rem',
        },
        '& .MuiAlert-message': {
          fontSize: { xs: '1rem', sm: '1.1rem', md: '1.2rem' },
          overflow: 'hidden',
        },
      }}
    >
      Du bist nicht angemeldet!
    </Alert>
  );
};

export default WarningComponent;
