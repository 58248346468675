import React from 'react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import Layout from './components/Layout/Layout';
import { UserProvider } from './contexts/UserContext';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import UserProfilePage from './components/UserProfilePage/UserProfilePage';
import { WindowWidthProvider } from './contexts/WindowWidthContext';
import TrackMonthlyRoot from './components/Timesheet/TimesheetRoot';
import HrPrompt from './components/hr_promp/HrPrompt';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import UserOverview from './components/roleManagement/UserOverview';
import LandingPage from './components/landing_page/LandingPage';
import CookieConsent, { consentGiven } from './components/legal/CookieConsent'; // Import the CookieConsent component
import { SnackbarProvider } from './contexts/SnackbarProvider';
import AccountingTable from './components/accountingOverview/AccountingTable';
import HrOverviewRequestWrapper from './components/hr_overview/HrOverviewRequestWrapper';
import HrOverviewHashWrapper from './components/hr_overview/HrOverviewHashWrapper';
import ImprintPage from './components/legal/ImprintPage';
import InfoPage from './components/general/InfoPage';
import ScrollToTop from './components/Layout/ScrollToTop';

function App() {
  return (
    <Router>
      <ScrollToTop />
      <SnackbarProvider>
        {consentGiven && (
          <GoogleOAuthProvider
            clientId={process.env.REACT_APP_OAUTH_GOOGLE_CLIENTID || ''}
          >
            <UserProvider>
              <WindowWidthProvider>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale="de"
                >
                  <Layout>
                    <Routes>
                      <Route path="/" element={<LandingPage />} />
                      <Route path="/user" element={<UserProfilePage />} />
                      <Route
                        path="/trackMonthly/:startDate/:endDate/:uuid"
                        element={<TrackMonthlyRoot />}
                      />
                      <Route
                        path="/trackMonthly"
                        element={<TrackMonthlyRoot />}
                      />
                      <Route path="/hrprompt" element={<HrPrompt />} />
                      <Route
                        path="/hroverview/:startDate/:endDate/:groupRole"
                        element={<HrOverviewRequestWrapper />}
                      />
                      <Route
                        path="/hroverview/:hash"
                        element={<HrOverviewHashWrapper />}
                      />
                      <Route path="/useroverview" element={<UserOverview />} />
                      <Route
                        path="/accountingoverview"
                        element={<AccountingTable />}
                      />
                      <Route path="/info" element={<InfoPage />} />
                      <Route path="/impressum" element={<ImprintPage />} />
                      <Route path="/imprint" element={<ImprintPage />} />
                    </Routes>
                  </Layout>
                </LocalizationProvider>
              </WindowWidthProvider>
            </UserProvider>
          </GoogleOAuthProvider>
        )}
        {!consentGiven && (
          <WindowWidthProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="de">
              <Layout>
                <CookieConsent />
                <Routes>
                  <Route path="/" element={<LandingPage />} />
                  <Route path="/info" element={<InfoPage />} />
                  <Route path="/impressum" element={<ImprintPage />} />
                  <Route path="/imprint" element={<ImprintPage />} />
                </Routes>
              </Layout>
            </LocalizationProvider>
          </WindowWidthProvider>
        )}
      </SnackbarProvider>
    </Router>
  );
}

export default App;
