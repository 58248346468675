import React, { useCallback, useRef } from 'react';
import '../../Timesheet.css';
import './ClockCell.css';
import { DesktopTimePicker, renderTimeViewClock } from '@mui/x-date-pickers';
import { Dayjs } from 'dayjs';

const ClockCell = ({
  index,
  isDisabled,
  selectedTime,
  setSelectedTime,
}: {
  index: number;
  isDisabled: boolean;
  selectedTime: Dayjs | null;
  setSelectedTime: (index: number, newTime: Dayjs | null) => void;
}) => {
  // Ref to store the debounce timer
  const debounceTimer = useRef<ReturnType<typeof setTimeout> | null>(null);

  // Handle time change with debounce
  const handleTimeChange = useCallback(
    (newTime: Dayjs | null) => {
      if (debounceTimer.current) {
        clearTimeout(debounceTimer.current);
      }

      debounceTimer.current = setTimeout(() => {
        if (newTime && newTime.isValid()) {
          setSelectedTime(index, newTime);
        }
      }, 100);
    },
    [index, setSelectedTime],
  );

  return (
    <td className={'no-column-divider to'}>
      <DesktopTimePicker
        value={selectedTime} // Bind state to picker value
        onChange={handleTimeChange} // Handle time change
        disabled={isDisabled}
        className="clock-cell-input"
        viewRenderers={{
          hours: renderTimeViewClock,
          minutes: renderTimeViewClock,
        }}
        ampm={false}
        minutesStep={15}
      />
    </td>
  );
};

export default ClockCell;
