import React, { createContext, useContext, useState, ReactNode } from 'react';
import CustomSnackbar from '../components/snackbar/CustomSnackbar';
import { ApiError } from '../api';
interface SnackbarContextProps {
  showSnackbar: (
    message: string,
    severity?: 'info' | 'success' | 'warning' | 'error',
    error?: ApiError | undefined,
  ) => void;
}

const SnackbarContext = createContext<SnackbarContextProps | undefined>(
  undefined,
);

export const SnackbarProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState<
    'info' | 'success' | 'warning' | 'error'
  >('info');

  const showSnackbar = (
    message: string,
    severity: 'info' | 'success' | 'warning' | 'error' = 'info',
    error: ApiError | undefined,
  ) => {
    const errormsg = error
      ? error !== undefined
        ? error.body.message
        : 'unspecified'
      : '';
    setSnackbarMessage(message + '\n' + errormsg);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
    setSnackbarMessage('');
  };

  return (
    <SnackbarContext.Provider value={{ showSnackbar }}>
      {children}
      <CustomSnackbar
        open={snackbarOpen}
        message={snackbarMessage}
        onClose={handleCloseSnackbar}
        severity={snackbarSeverity}
      />
    </SnackbarContext.Provider>
  );
};

export const useSnackbar = () => {
  const context = useContext(SnackbarContext);
  if (context === undefined) {
    throw new Error('useSnackbar must be used within a SnackbarProvider');
  }
  return context;
};
