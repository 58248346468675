import React, { useEffect, useState } from 'react';
import Avatar from '@mui/material/Avatar';
import { User } from '../api';

const CACHE_DURATION_MS = 24 * 60 * 60 * 1000; // 1 day in milliseconds

// Function to fetch image and convert to Base64
const fetchImageAsBase64 = async (imageUrl: string): Promise<string> => {
  try {
    const response = await fetch(imageUrl);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const blob = await response.blob();
    const reader = new FileReader();

    return new Promise((resolve, reject) => {
      reader.onloadend = () => {
        if (reader.result) {
          const base64Data = reader.result as string;
          const mimeType = blob.type;
          resolve(`data:${mimeType};base64,${base64Data.split(',')[1]}`);
        } else {
          reject(new Error('Error converting image to Base64'));
        }
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob); // Converts blob to Base64
    });
  } catch (error) {
    console.error('Error fetching image as Base64:', error);
    return '';
  }
};

interface UserAvatarProps {
  user: User;
  style?: React.CSSProperties;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
}

const UserAvatar: React.FC<UserAvatarProps> = ({ user, style, onClick }) => {
  const [avatarSrc, setAvatarSrc] = useState<string | undefined>(undefined);

  useEffect(() => {
    const cacheKey = 'avatar_' + user.uuid;
    const cacheTimestampKey = cacheKey + '_timestamp';

    const cachedAvatar = localStorage.getItem(cacheKey);
    const cacheTimestamp = localStorage.getItem(cacheTimestampKey);

    const isCacheValid =
      cacheTimestamp &&
      Date.now() - parseInt(cacheTimestamp) < CACHE_DURATION_MS;

    if (cachedAvatar && isCacheValid) {
      setAvatarSrc(cachedAvatar); // Use cached image if valid
    } else {
      fetchImageAsBase64(user.avatarLink || '')
        .then((base64Image) => {
          setAvatarSrc(base64Image); // Set the image source
          localStorage.setItem(cacheKey, base64Image); // Cache the image
          localStorage.setItem(cacheTimestampKey, Date.now().toString()); // Cache the timestamp
        })
        .catch((error) => console.error('Error fetching avatar image:', error));
    }
  }, [user]);

  return (
    <Avatar
      alt={`${user.firstName} ${user.lastName}`}
      src={avatarSrc}
      style={{ ...style }}
      onClick={onClick}
      sx={{ width: 40, height: 40 }}
    />
  );
};

export default UserAvatar;
