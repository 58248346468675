import React, { useEffect, useState } from 'react';
import { AccountingOverview, AccountingService } from '../../api';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from '../../contexts/SnackbarProvider';
import CustomTable from './HrOverview';

const HrOverviewRequestWrapper: React.FC = () => {
  const [accountingOverview, setAccountingOverview] = useState<
    AccountingOverview | undefined
  >(undefined);

  const { startDate, endDate, groupRole } = useParams();
  const { showSnackbar } = useSnackbar();
  const navigate = useNavigate();
  useEffect(() => {
    // Request API /overview
    const requestBody = {
      grouprole: groupRole || '',
      startDate: startDate || '',
      endDate: endDate || '',
    };
    AccountingService.getAccountingOverview(requestBody)
      .then((response) => {
        setAccountingOverview(response);
      })
      .catch((error) => {
        showSnackbar('An error occurred: ', 'error', error);
        navigate('/hrprompt');
      });
  }, []);

  return accountingOverview ? (
    <CustomTable accountingOverview={accountingOverview} billable={true} />
  ) : (
    <></>
  );
};

export default HrOverviewRequestWrapper;
