import React, { useCallback } from 'react';
import '../../Timesheet.css';
import DateCell from '../cells/DateCell';
import StateCell from '../cells/StateCell';
import ClockCell from '../cells/ClockCell';
import PauseCell from '../cells/PauseCell';
import WorkTimeCell from '../cells/WorkTimeCell';
import ExtraCell from '../cells/ExtraCell';
import TextCell from '../cells/TextCell';
import dayjs, { Dayjs } from 'dayjs';
import { StatusEnum } from '../../../../api';
import { Row } from '../../../../contexts/TimesheetContext';
import { Typography } from '@mui/material';

const typeNames = ['error', '', 'Urlaub', 'Krank', 'Abwesend'];

interface DefaultRowProps {
  index: number;
  row: Row;
  updateFrom: (index: number, newFrom: Dayjs | null) => void;
  updateTo: (index: number, newTo: Dayjs | null) => void;
  updatePause: (index: number, newPause: Dayjs | null) => void;
  updateNote: (index: number, newNote: string | number) => void;
  isTypeDesktop: boolean;
  toggleDrawer: () => void;
}

const DefaultRow = React.memo(
  ({
    index,
    row,
    updateFrom,
    updateTo,
    updatePause,
    updateNote,
    isTypeDesktop,
    toggleDrawer,
  }: DefaultRowProps) => {
    const name: string = typeNames[row.type as number];

    // Memoized callbacks
    const handleUpdateNote = useCallback(
      (newNote: string | number) => updateNote(index, newNote),
      [index, updateNote],
    );

    const handleToggleDrawer = useCallback(
      () => toggleDrawer(),
      [toggleDrawer],
    );
    return (
      <tr
        className={`no-row-divider datum ${row.block ? 'blocked' : ''} ${row.date.isSame(dayjs(), 'day') ? 'today' : ''}`}
      >
        <DateCell date={row.date.toDate()} />

        {/* default render input form (with start/end time, ...) */}
        {!row.block && row.holiday === '' && row.type === 1 && (
          <>
            <StateCell state={row.state} />
            <ClockCell
              index={index}
              selectedTime={row.from}
              setSelectedTime={updateFrom}
              isDisabled={
                row.state === StatusEnum.LOCKED ||
                row.state === StatusEnum.APPROVED ||
                row.block
              }
            />
            <ClockCell
              index={index}
              selectedTime={row.to}
              setSelectedTime={updateTo}
              isDisabled={
                row.state === StatusEnum.LOCKED ||
                row.state === StatusEnum.APPROVED ||
                row.block
              }
            />
            <PauseCell
              index={index}
              selectedTime={row.pause}
              setSelectedTime={updatePause}
              isDisabled={
                row.state === StatusEnum.LOCKED ||
                row.state === StatusEnum.APPROVED ||
                row.block
              }
            />
            <WorkTimeCell
              index={index}
              workSum={row.workingHours}
              isDisabled={
                row.state === StatusEnum.LOCKED ||
                row.state === StatusEnum.APPROVED ||
                row.block
              }
              toggleDrawer={handleToggleDrawer}
            />
            <ExtraCell
              extra={row.type}
              setExtra={handleUpdateNote}
              isDisabled={
                row.state === StatusEnum.LOCKED ||
                row.state === StatusEnum.APPROVED ||
                row.block
              }
            />
          </>
        )}

        {/* special type row (Urlaub, Abwesend, Krank) */}
        {!row.block && row.holiday === '' && (row.type as number) > 1 && (
          <>
            <StateCell state={row.state} />
            {isTypeDesktop && <TextCell text={name} />}
            <WorkTimeCell
              index={index}
              workSum={row.workingHours}
              isDisabled={true}
              toggleDrawer={handleToggleDrawer}
            />
            <ExtraCell
              extra={row.type}
              setExtra={handleUpdateNote}
              isDisabled={
                row.state === StatusEnum.LOCKED ||
                row.state === StatusEnum.APPROVED ||
                row.block
              }
            />
          </>
        )}

        {/* don't render input form + information text */}
        {row.block && row.holiday === '' && (
          <>
            <StateCell state={StatusEnum.UNKNOWN} />
            {isTypeDesktop && <TextCell text="Wochenende" />}
            <WorkTimeCell
              index={index}
              workSum={0}
              isDisabled={true}
              toggleDrawer={handleToggleDrawer}
            />
            <td />
          </>
        )}

        {/* don't render input form + information text */}
        {row.holiday !== '' && (
          <>
            <StateCell state={row.state} customColor="#66b3ff" />
            {isTypeDesktop && <TextCell text={'Feiertag: ' + row.holiday} />}
            <WorkTimeCell
              index={index}
              workSum={row.workingHours}
              isDisabled={true}
              toggleDrawer={() => {}}
            />
            <td colSpan={3} className="information">
              <Typography
                style={{
                  fontWeight: 'bold',
                  textAlign: 'center',
                  margin: '0px',
                  padding: '0px',
                  fontSize: '1.6em',
                }}
              >
                {'🎉🎊'}
              </Typography>
            </td>
            <td />
          </>
        )}
      </tr>
    );
  },
  (prevProps, nextProps) => {
    // Only re-render if any of these props change
    return (
      prevProps.row.date.month() === nextProps.row.date.month() &&
      prevProps.row.from === nextProps.row.from &&
      prevProps.row.to === nextProps.row.to &&
      prevProps.row.type === nextProps.row.type &&
      prevProps.row.state === nextProps.row.state &&
      prevProps.index === nextProps.index &&
      prevProps.isTypeDesktop === nextProps.isTypeDesktop
    );
  },
);

DefaultRow.displayName = 'DefaultRow';
export default DefaultRow;
