/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { User } from '../models/User';
import type { UserInvitation } from '../models/UserInvitation';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class UserService {
    /**
     * Retrieve user Information about yourself
     * Retrieve information about the authenticated user
     * @returns User User object
     * @throws ApiError
     */
    public static getOwnUser(): CancelablePromise<User> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/me',
            errors: {
                400: `Bad client request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }
    /**
     * Retrieve a list of roles
     * Retrieve a list of all roles that are visible to you.
     * You must have permission to query users and roles.
     * Some roles may not be visible due to your user privileges
     *
     * @returns string List of all role names
     * @throws ApiError
     */
    public static getAllRoles(): CancelablePromise<Array<string>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/roles',
            errors: {
                400: `Bad client request`,
                401: `Unauthorized`,
                403: `Forbidden`,
            },
        });
    }
    /**
     * Retrieve user Information about any user
     * Retrieve information about a user by their UUIDs, or all users.
     * You must have permission to query users.
     *
     * @param uuid Filter for user uuid
     * @param role Filter for users by role
     * @returns User User object
     * @throws ApiError
     */
    public static getUsers(
        uuid?: string,
        role?: string,
    ): CancelablePromise<Array<User>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/users',
            query: {
                'uuid': uuid,
                'role': role,
            },
            errors: {
                400: `Bad client request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }
    /**
     * Invite a new user to Sapling
     * Invites a new user to Sapling, which can then login independently.
     * Your role must permit you to create new users.
     *
     * @param requestBody New user data
     * @returns UserInvitation Success, returns provided user information
     * @throws ApiError
     */
    public static inviteUser(
        requestBody: UserInvitation,
    ): CancelablePromise<UserInvitation> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/users',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad client request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Internal Server Error`,
            },
        });
    }
    /**
     * Update a user
     * Allows updating some of an existing users' values
     * @param uuid The UUID of the user this request is about
     * @param requestBody Updated user data
     * @returns User Succcess, returns updated user object
     * @throws ApiError
     */
    public static updateUser(
        uuid: string,
        requestBody: User,
    ): CancelablePromise<User> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/users/{uuid}',
            path: {
                'uuid': uuid,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad client request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }
    /**
     * Delete a user
     * Deletes a user by their UUID
     * @param uuid The UUID of the user this request is about
     * @returns any Success, user deleted
     * @throws ApiError
     */
    public static deleteUser(
        uuid: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/users/{uuid}',
            path: {
                'uuid': uuid,
            },
            errors: {
                400: `Bad client request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }
}
