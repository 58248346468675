import React, { memo } from 'react';
import '../../Timesheet.css';
import { StatusEnum } from '../../../../api';
import { getBackgroundColor } from '../../tool';
import { useTheme } from '@mui/material';

const StateCell = ({
  state,
  customColor,
}: {
  state: StatusEnum;
  customColor?: string;
}) => {
  const theme = useTheme();
  return (
    <td
      className={'no-column-divider datum'}
      style={{
        backgroundColor: customColor
          ? customColor
          : getBackgroundColor(state, theme),
        width: '12px',
        padding: '0px',
      }}
    ></td>
  );
};

export default memo(StateCell);
