/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AccountingOverview } from '../models/AccountingOverview';
import type { AccountingOvReq } from '../models/AccountingOvReq';
import type { Lastbalance } from '../models/Lastbalance';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class AccountingService {
    /**
     * Generate and retrieve accounting overview
     * Retrieve company worktime overviews ("Abrechnung machen für den Monat").
     * This is a post request deliberately to avoid caching and to use request bodies for
     * better urls and bookmarks. Furthermore, this requests actually generates data.
     * This request will lock timesheets for the requested usergroups. You may cancel via /cancel.
     * Timeouts after 5 minutes without any new request.
     * If an accoutning is already active, you receive the old overview.
     * The hash refers to the accounting process in case multiple accountings are active at the same time. (Future feature)
     * The timesheet summaries will contain only new hours that were not yet approved.
     *
     * @param requestBody Balancing overview request parameters
     * @returns AccountingOverview Balancing Overview
     * @throws ApiError
     */
    public static getAccountingOverview(
        requestBody: AccountingOvReq,
    ): CancelablePromise<AccountingOverview> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/accounting/overview',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad client request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Internal Server Error`,
            },
        });
    }
    /**
     * Cancel accounting state
     * Cancel a currently running accounting session.
     * You must be the user that started an accounting.
     *
     * @param requestBody Accounting hash to cancel for
     * @returns any Everything okay.
     * @throws ApiError
     */
    public static cancelAccounting(
        requestBody: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/accounting/cancel',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad client request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Internal Server Error`,
            },
        });
    }
    /**
     * Accept timesheets and forward to accounting
     * Accepts the accounting overview / set of timesheet that are
     * identified by a hash (retrieved via accounting overview). The worktimes
     * will be marked as accepted for the given timeframe and the summary is
     * marked as fully processed.
     *
     * The hash is retrieved via the last AccountingOverview request and ensures
     * that data was not changed since the last display request and the confirmation.
     *
     * @param requestBody Accounting overview hash
     * @returns AccountingOverview Accepted balancing overview for your convenience
     * @throws ApiError
     */
    public static confirmAccounting(
        requestBody: string,
    ): CancelablePromise<AccountingOverview> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/accounting/confirm',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad client request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Internal Server Error`,
            },
        });
    }
    /**
     * Retrieve old accounting overviews
     * Retrieve already approved company worktime overviews from the past.
     * You may provide a hash to acquire a specific accounting sheet.
     *
     * @param hash
     * @returns AccountingOverview Balancing Overviews from the past, newest first
     * @throws ApiError
     */
    public static getAccountingHistory(
        hash?: string,
    ): CancelablePromise<Array<AccountingOverview>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/accounting/history',
            query: {
                'hash': hash,
            },
            errors: {
                400: `Bad client request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Internal Server Error`,
            },
        });
    }
    /**
     * Get last accounting date
     * This endpoint returns the last date for which an accounting was done and also the first date that might be
     * relevant for the next accounting round. The two dates might differ if e.g.
     * usergroups have changed, but normally dates should be the same.
     *
     * TODO: This feature is currently not fully implemented and dates are always the same. Subject to change.
     *
     * @param usergroups Optionally filter for specific usergroup
     * @returns Lastbalance Last Accounting date for all visible usergroups
     * @throws ApiError
     */
    public static getLastBalanced(
        usergroups?: string,
    ): CancelablePromise<Array<Lastbalance>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/accounting/lastbalanced/',
            query: {
                'usergroups': usergroups,
            },
            errors: {
                400: `Bad client request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                500: `Internal Server Error`,
            },
        });
    }
}
