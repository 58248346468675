import React, { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate hook for React Router v6
import { useUser } from '../../contexts/UserContext';
import { CredentialResponse, GoogleLogin } from '@react-oauth/google';
import { OpenAPI } from '../../api';
import { CircularProgress, Menu, MenuItem } from '@mui/material';
import UserAvatar from '../UserAvatar';
interface SignInProps {}

const SignIn: FC<SignInProps> = () => {
  const { user, loading, fetchUser, resetUser } = useUser();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const navigate = useNavigate(); // Initialize the navigate function from useNavigate hook

  const handleGoogleLoginSuccess = (credentialResponse: CredentialResponse) => {
    if (credentialResponse.credential) {
      localStorage.setItem('token', credentialResponse.credential);
      OpenAPI.TOKEN = credentialResponse.credential;
      fetchUser();
    }
  };

  const handleAvatarClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleProfileClick = () => {
    navigate('/user'); // Navigate to /user route
    handleMenuClose(); // Close the menu after navigation
  };

  const handleLogoutClick = () => {
    resetUser();
    navigate('/');
    handleMenuClose();
  };

  return (
    <div style={{ position: 'relative', display: 'inline-block' }}>
      {loading ? (
        <CircularProgress />
      ) : user ? (
        <>
          <UserAvatar
            user={user}
            style={{
              boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.4)',
              cursor: 'pointer',
            }}
            onClick={handleAvatarClick}
          />
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <MenuItem onClick={handleProfileClick}>Profile</MenuItem>
            <MenuItem onClick={handleLogoutClick}>Logout</MenuItem>
          </Menu>
        </>
      ) : (
        <GoogleLogin
          onSuccess={handleGoogleLoginSuccess}
          onError={() => {
            console.log('Login Failed');
          }}
          useOneTap
        />
      )}
    </div>
  );
};

export default SignIn;
