import { Alert } from '@mui/material';
import React from 'react';
import FavoriteIcon from '@mui/icons-material/Favorite';

const WelcomeComponent = () => {
  return (
    <Alert
      severity="error"
      icon={<FavoriteIcon sx={{ color: '#ffffff', fontSize: '2.5rem' }} />}
      sx={{
        margin: '20px',
        marginBottom: '2rem',
        fontSize: '1.2rem',
        padding: '1rem 2rem',
        borderRadius: '8px',
        backgroundColor: '#DA70D6',
        color: '#ffffff',
        display: 'flex',
        alignItems: 'center',
        '& .MuiAlert-icon': {
          fontSize: '2.5rem',
          color: '#ffffff',
          marginRight: '1rem',
        },
        '& .MuiAlert-message': {
          fontSize: { xs: '1rem', sm: '1.1rem', md: '1.2rem' },
          overflow: 'hidden',
        },
      }}
    >
      Paul ist der Beste!
    </Alert>
  );
};

export default WelcomeComponent;
