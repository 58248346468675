import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { OpenAPI } from './api';
import { ThemeProvider } from '@emotion/react';
import theme from './theme';
import '@fontsource/readex-pro';
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

// default init for token and api url
const token = localStorage.getItem('token') as string;
OpenAPI.TOKEN = token;
OpenAPI.BASE = process.env.REACT_APP_API_URL || 'http://localhost:8080';

root.render(
  <ThemeProvider theme={theme}>
    <App />
  </ThemeProvider>,
);
