import React from 'react';
import Section from './Section';
import timesheetDemoSrc from '../../../images/demo_timesheet.png';
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  useTheme,
} from '@mui/material';
import { getBackgroundColor } from '../../Timesheet/tool';
import { StatusEnum } from '../../../api';

const TimesheetDemo = ({
  index,
  buttonDisabled,
}: {
  index: number;
  buttonDisabled: boolean;
}) => {
  const theme = useTheme();
  return (
    <Section
      index={index}
      title="Zeiterfassung"
      madeFor="Werkstudierende & Arbeitnehmer"
      buttonLink="/trackMonthly"
      imageSrc={timesheetDemoSrc}
      buttonDisabled={buttonDisabled}
    >
      <Typography
        variant="body1"
        sx={{
          fontSize: { xs: '1rem', sm: '1.1rem', md: '1.2rem' },
          marginBottom: '1rem',
        }}
      >
        Werkstudierende können ihre Stunden in ein smartes Timesheet eintragen.
        Sowohl den Studierenden als auch der HR-Abteilung wird eine klare und
        transparente Übersicht über bereits abgerechnete, fehlerhaft erfasste
        oder noch offene Stunden geboten. Mit nur einem Klick können sich Nutzer
        als krank, im Urlaub oder abwesend markieren – ohne dabei manuell
        Stunden eintragen zu müssen.
      </Typography>
      <List sx={{ marginTop: '2rem' }}>
        <ListItem>
          <ListItemIcon>
            <Box
              sx={{
                backgroundColor: getBackgroundColor(StatusEnum.APPROVED, theme),
                width: 20,
                height: 20,
                borderRadius: '4px',
              }}
            />
          </ListItemIcon>
          <ListItemText primary="Die Stunden wurden bereits abgerechnet." />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <Box
              sx={{
                backgroundColor: getBackgroundColor(StatusEnum.GOOD, theme),
                width: 20,
                height: 20,
                borderRadius: '4px',
              }}
            />
          </ListItemIcon>
          <ListItemText primary="Stunden sind korrekt eingetragen." />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <Box
              sx={{
                backgroundColor: getBackgroundColor(StatusEnum.WARNING, theme),
                width: 20,
                height: 20,
                borderRadius: '4px',
              }}
            />
          </ListItemIcon>
          <ListItemText primary="Verletzung der Arbeitszeitreglung!" />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <Box
              sx={{
                backgroundColor: getBackgroundColor(StatusEnum.ERROR, theme),
                width: 20,
                height: 20,
                borderRadius: '4px',
              }}
            />
          </ListItemIcon>
          <ListItemText primary="Massiver Fehler!" />
        </ListItem>
        <ListItem>
          <ListItemIcon>
            <Box
              sx={{
                backgroundColor: 'grey',
                width: 20,
                height: 20,
                borderRadius: '4px',
              }}
            />
          </ListItemIcon>
          <ListItemText primary="Einträge sind gesperrt!" />
        </ListItem>
      </List>
    </Section>
  );
};

export default TimesheetDemo;
