/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Timesheet } from '../models/Timesheet';
import type { WorkingDay } from '../models/WorkingDay';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class TimesheetService {
    /**
     * Track time and see tracked time
     * This endpoint allows to track time for a user, update already existing entries, or delete them.
     * Furthermore, it may be used with an empty request body to simply obtain already tracked time.
     * POST the day as it should be and it will be completely overwritten, if the data passes a basic
     * validity test. The smallest unit of timetracking is deliberately a day, as to avoid invalid entry
     * IDs etc, so please note that **posting a day will completely overwrite an existing day**.
     * If a request body is present, startDate and endDate must enclose all modified days!
     *
     * @param uuid The UUID of the user this request is about
     * @param startDate Start day for relevant timesheets (inclusive)
     * @param endDate End day for relevant timesheets (inclusive)
     * @param requestBody Full (updated) working days.
     * @returns Timesheet Timesheet
     * @throws ApiError
     */
    public static updateTimesheet(
        uuid: string,
        startDate: string,
        endDate: string,
        requestBody?: Array<WorkingDay>,
    ): CancelablePromise<Timesheet> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/timesheet/{uuid}',
            path: {
                'uuid': uuid,
            },
            query: {
                'startDate': startDate,
                'endDate': endDate,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad client request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Internal Server Error`,
            },
        });
    }
}
