import React from 'react';
import '../../Timesheet.css';
const HeaderRow = React.memo(() => {
  return (
    <tr>
      <th style={{ textAlign: 'center' }} className={'column-divider datum'}>
        Tag
      </th>
      <th className={'no-column-divider'} style={{ padding: '0px' }}></th>
      <th className={'no-column-divider from'}>Von</th>
      <th className={'no-column-divider to'}>Bis</th>
      <th className={'no-column-divider pause'}>Pause</th>
      <th style={{ textAlign: 'center' }} className={'no-column-divider work'}>
        Summe
      </th>
      <th style={{ textAlign: 'center' }} className={'no-column-divider'}>
        Extra
      </th>
    </tr>
  );
});

HeaderRow.displayName = 'HeaderRow';
export default HeaderRow;
