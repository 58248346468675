import React from 'react';
import '../../Timesheet.css';
import { useWindowWidth } from '../../../../contexts/WindowWidthContext';
import { formatDateInGerman } from '../../tool';
const WeekTitleRow = ({ start, end }: { start: Date; end: Date }) => {
  const { isType } = useWindowWidth();
  return (
    <tr
      className="no-row-divider"
      style={{
        backgroundColor: '#f5f5f5',
        borderTop: '1px solid #ccc',
      }}
    >
      <td
        colSpan={!isType('desktop') ? 4 : 7}
        className={'no-column-dividerdatum information'}
      >
        {formatDateInGerman(start).date} - {formatDateInGerman(end).date}
      </td>
    </tr>
  );
};

export default WeekTitleRow;
