import React from 'react';
import '../../Timesheet.css';
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
const extraLabels = ['Arbeit', 'Urlaub', 'Krank', 'Abwesend'];

const ExtraCell = ({
  isDisabled,
  extra,
  setExtra,
}: {
  isDisabled: boolean;
  extra: string | number;
  setExtra: (newExtra: string | number) => void;
}) => {
  const handleChange = (event: SelectChangeEvent<typeof extra>) => {
    setExtra(event.target.value);
  };

  return (
    <td style={{ textAlign: 'center' }} className={'no-column-divider'}>
      <Select
        disabled={isDisabled}
        id="extra-selection"
        value={extra}
        onChange={handleChange}
        label=""
        renderValue={(selected: string | number) =>
          typeof selected === 'number'
            ? selected === 1
              ? ''
              : extraLabels[selected - 1][0]
            : selected[0]
        }
        sx={{
          '& .MuiSelect-select': {
            padding: '0px',
            height: '32px !important', // Set the height of the Select component
            minHeight: '32px !important', // Set the height of the Select component
            textAlign: 'center', // Center the text
            lineHeight: '32px', // Center text vertically by matching the height
            display: 'flex',
            alignItems: 'center', // Center text vertically using flexbox
            justifyContent: 'center',
            backgroundColor: 'white',
          },
          '& .MuiSelect-icon': {
            top: 'calc(50% - 12px)', // Adjust icon position if needed
          },
          width: '72px',
        }}
      >
        {/* Value must be empty string so the field appears empty if Arbeit is selected*/}
        <MenuItem value={1}>
          <span style={{ color: 'silver' }}>Arbeit</span>
        </MenuItem>
        <MenuItem value={2}>Urlaub</MenuItem>
        <MenuItem value={3}>Krank</MenuItem>
        <MenuItem value={4}>Abwesend</MenuItem>
      </Select>
    </td>
  );
};

export default ExtraCell;
