// UserContext.tsx
import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from 'react';
import { ApiError, UserService, OpenAPI, User } from '../api';

// Define the shape of context data
interface UserContextType {
  user: User | null;
  loading: boolean;
  error: string | null;
  fetchUser: () => void; // Function to fetch user data
  resetUser: () => void; // Function to reset user context
  catchUnauthorizedError: (error: ApiError) => void; // Function to reset user context
}

// Create the context with initial values
const UserContext = createContext<UserContextType>({
  user: null,
  loading: false,
  error: null,
  fetchUser: () => {}, // Initial empty function
  resetUser: () => {}, // Initial empty reset function
  catchUnauthorizedError: () => {},
});

// Custom hook to consume the UserContext
export const useUser = () => useContext(UserContext);

// Define the type for UserProvider props
interface UserProviderProps {
  children: ReactNode; // ReactNode allows any JSX to be passed as children
}

// Provider component to manage user data
export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(false); // Initially set to false
  const [error, setError] = useState<string | null>(null);
  // Function to fetch user data
  const fetchUser = async () => {
    setLoading(true);
    try {
      await UserService.getOwnUser()
        .then((userData) => setUser(userData))
        .catch((error: ApiError) => {
          catchUnauthorizedError(error);
        });
    } finally {
      setLoading(false);
    }
  };

  // Function to reset user context
  const resetUser = () => {
    setUser(null);
    setLoading(false);
    setError(null);

    OpenAPI.TOKEN = undefined;
    localStorage.removeItem('token');
  };

  const catchUnauthorizedError = (error: ApiError) => {
    if (error instanceof ApiError && error.status == 401) {
      resetUser();
    }
  };
  useEffect(() => {
    if (OpenAPI.TOKEN) {
      fetchUser();
    }
  }, []);

  return (
    <UserContext.Provider
      value={{
        user,
        loading,
        error,
        fetchUser,
        resetUser,
        catchUnauthorizedError,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
