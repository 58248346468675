import React, { FC } from 'react';
import { Button } from '@mui/material';
import CookieIcon from '@mui/icons-material/Cookie';

const NoCookies: FC = () => {
  const handleClick = () => {
    window.location.reload(); // Reload as this will show cookie dialog
  };

  return (
    <div
      style={{
        position: 'relative',
        display: 'inline-block',
        marginTop: '5px',
        marginBottom: '5px',
      }}
    >
      <Button
        color="error"
        variant="contained"
        endIcon={<CookieIcon />}
        onClick={handleClick}
      >
        Cookie Dialog zeigen
      </Button>
    </div>
  );
};

export default NoCookies;
