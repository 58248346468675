import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
  useCallback,
} from 'react';

// Define the possible window width categories
type WindowWidthCategory = 'mobile' | 'medium' | 'desktop';

// Define the shape of the context value
interface WindowWidthContextType {
  isType: (type: WindowWidthCategory) => boolean;
}

// Create the context with an undefined default value
const WindowWidthContext = createContext<WindowWidthContextType | undefined>(
  undefined,
);

// Determine the category based on the window width
const determineCategory = (width: number): WindowWidthCategory => {
  if (width <= 400) return 'mobile';
  if (width <= 800) return 'medium';
  return 'desktop';
};

// Create the provider component
const WindowWidthProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [category, setCategory] = useState<WindowWidthCategory>(
    determineCategory(window.innerWidth),
  );

  const handleResize = useCallback(() => {
    setCategory(determineCategory(window.innerWidth));
  }, []);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  // Function to check if the current window width matches a given category
  const isType = useCallback(
    (type: WindowWidthCategory): boolean => {
      return category === type;
    },
    [category],
  );

  return (
    <WindowWidthContext.Provider value={{ isType }}>
      {children}
    </WindowWidthContext.Provider>
  );
};

// Create a custom hook to use the context
const useWindowWidth = (): WindowWidthContextType => {
  const context = useContext(WindowWidthContext);
  if (!context) {
    throw new Error('useWindowWidth must be used within a WindowWidthProvider');
  }
  return context;
};

export { WindowWidthProvider, useWindowWidth };
