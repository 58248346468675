/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum StatusEnum {
    APPROVED = 'approved',
    LOCKED = 'locked',
    GOOD = 'good',
    WARNING = 'warning',
    ERROR = 'error',
    UNKNOWN = 'unknown',
}
