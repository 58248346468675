import React from 'react';
import { Snackbar, Alert } from '@mui/material';
import { AlertProps } from '@mui/material/Alert';

interface SnackbarProps {
  open: boolean;
  message: string;
  onClose: () => void;
  severity?: AlertProps['severity'];
}

const CustomSnackbar: React.FC<SnackbarProps> = ({
  open,
  message,
  onClose,
  severity = 'info',
}) => {
  return (
    <Snackbar
      open={open}
      autoHideDuration={6000} // Adjust duration as needed
      onClose={onClose}
      message={message}
      action={
        <button
          onClick={onClose}
          style={{ color: 'inherit', fontSize: 24, margin: 8 }}
        >
          Close
        </button>
      }
    >
      <Alert
        onClose={onClose}
        severity={severity}
        sx={{ width: '100%', borderRadius: 2 }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default CustomSnackbar;
