import { Drawer } from '@mui/material';
import React, { useRef } from 'react';
import './MobileTimesheetDrawer.css'; // Import the CSS file for styling
import { MobileTimePicker, TimeField } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import { useDrawer } from '../../../../contexts/DrawerContext';
import { useTimesheet } from '../../../../contexts/TimesheetContext';

const MobileTimeSheetDrawer = () => {
  const { isDrawerOpen, toggleDrawer, pressedIndexRow } = useDrawer();

  const { rows, updateFrom, updatePause, updateTo } = useTimesheet();

  const debounceTimer = useRef<ReturnType<typeof setTimeout> | null>(null);
  const handleFrom = (newTime: Dayjs | null) => {
    if (newTime && newTime.isValid()) updateFrom(pressedIndexRow, newTime);
  };
  const handleTo = (newTime: Dayjs | null) => {
    if (newTime && newTime.isValid()) updateTo(pressedIndexRow, newTime);
  };
  const handlePause = (newTime: Dayjs | null) => {
    if (debounceTimer.current) {
      clearTimeout(debounceTimer.current);
    }

    debounceTimer.current = setTimeout(() => {
      if (newTime && newTime.isValid()) {
        updatePause(pressedIndexRow, newTime);
      }
    }, 500);
  };

  const timepickerSx = {
    // Custom styles to adjust height and width
    '& .MuiInputBase-root': {
      height: '32px', // Adjust height
    },
    '& input': {
      height: '100%', // Ensure input fits height
    },
    width: 'auto', // Adjust width
    maxWidth: '120px',
    // Adjust the size of the icon within the picker
    '& .MuiInputAdornment-root': {
      margin: '0px',
    },
  };

  return (
    <Drawer anchor="bottom" open={isDrawerOpen} onClose={toggleDrawer}>
      <div className="drawer-content">
        <div className="grid-container">
          <div className="header">Von</div>
          <div className="header">Bis</div>
          <div className="header">Pause</div>

          <div className="content">
            <MobileTimePicker
              value={rows[pressedIndexRow]?.from} // Bind state to picker value
              onChange={handleFrom} // Handle time change
              disabled={false}
              minutesStep={15}
              // TODO: Disable night selection based on userdata or allow all time entries?
              sx={timepickerSx}
              ampm={false}
            />
          </div>
          <div className="content">
            <MobileTimePicker
              value={rows[pressedIndexRow]?.to} // Bind state to picker value
              onChange={handleTo} // Handle time change
              disabled={false}
              minutesStep={15}
              sx={timepickerSx}
              ampm={false}
            />
          </div>
          <div className="content">
            <TimeField
              value={rows[pressedIndexRow]?.pause} // Bind state to picker value
              onChange={handlePause} // Handle time change
              ampm={false}
              disabled={false}
              defaultValue={dayjs('2022-04-17T00:00')}
              sx={{
                // Custom styles to adjust height and width
                '& .MuiInputBase-root': {
                  height: '32px', // Adjust height
                },
                '& input': {
                  height: '100%', // Ensure input fits height
                },
                width: '72px',
              }}
            />
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default MobileTimeSheetDrawer;
