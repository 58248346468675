import React from 'react';
import './Timesheet.css';
import TrackMonthly from './Timesheet';
import { TimesheetProvider } from '../../contexts/TimesheetContext';
import TrackMonthlyBanner from './TimesheetBanner';
import { DrawerProvider } from '../../contexts/DrawerContext';
import { useMediaQuery } from '@mui/material';
import theme from '../../theme';

const TrackMonthlyRoot = () => {
  const desktopLayout = useMediaQuery(theme.breakpoints.up('md'));
  return (
    <TimesheetProvider>
      <div className="root">
        <TrackMonthlyBanner />
        <div
          className="banner"
          style={{ paddingTop: desktopLayout ? '20px' : '0px' }}
        >
          <DrawerProvider>
            <TrackMonthly />
          </DrawerProvider>
        </div>
      </div>
    </TimesheetProvider>
  );
};

export default TrackMonthlyRoot;
