import React, { useEffect, useState } from 'react';
import { AccountingOverview, AccountingService } from '../../api';
import { useParams } from 'react-router-dom';
import CustomTable from './HrOverview';

const HrOverviewHashWrapper: React.FC = () => {
  const { hash } = useParams();

  const [data, setData] = useState<AccountingOverview | undefined>(undefined);
  useEffect(() => {
    AccountingService.getAccountingHistory(hash).then((x) => {
      if (x.length > 0) setData(x[0]);
    });
  }, [hash]);

  return data ? (
    <CustomTable accountingOverview={data} billable={false} />
  ) : (
    <></>
  );
};

export default HrOverviewHashWrapper;
