import React, { useEffect, useState } from 'react';
import { AccountingOverview, AccountingService } from '../../api';
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { useNavigate } from 'react-router-dom';
import { convertToHourMinute } from '../Timesheet/tool';
const AccountingTable = () => {
  const navigate = useNavigate();
  const [data, setData] = useState<AccountingOverview[]>([]);
  useEffect(() => {
    AccountingService.getAccountingHistory().then((x) => setData(x));
  }, []);

  const handleRowClick = (overview: AccountingOverview) => {
    navigate(`/hroverview/${overview.accountingHash}`);
  };

  const tableBodyCellSx = { padding: '16px 8px' };
  const tableHeadCellSx = {
    fontSize: { xs: '0.7rem', sm: '0.9rem', md: '1.0rem' },
    fontWeight: 'bold',
    padding: '16px 8px',
  };
  const tableBodyTypoSx = {
    fontSize: { xs: '0.7rem', sm: '0.9rem', md: '1.0rem' },
    overflowWrap: 'break-word',
    wordBreak: 'break-word',
  };

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        margin: '10px',
      }}
    >
      <TableContainer
        component={Paper}
        elevation={3}
        sx={{
          width: '100%',
          maxWidth: '1200px',
          borderRadius: '12px',
          overflow: 'hidden',
          padding: '16px',
        }}
      >
        <Typography variant="h5" gutterBottom align="center" fontWeight="bold">
          Vergangene Abrechnungen
        </Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={tableHeadCellSx}>Gruppe</TableCell>
              <TableCell sx={tableHeadCellSx}>Start</TableCell>
              <TableCell sx={tableHeadCellSx}>Ende</TableCell>
              {isDesktop && (
                <TableCell sx={tableHeadCellSx}>Anzahl Nutzer</TableCell>
              )}
              <TableCell sx={tableHeadCellSx}>Σ</TableCell>
              <TableCell
                sx={{
                  fontSize: { xs: '0.7rem', sm: '0.9rem', md: '1.0rem' },
                  padding: '16px 8px',
                  color: 'silver',
                }}
              >
                Σ!
              </TableCell>

              <TableCell sx={tableHeadCellSx}>Aktionen</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data && data.length > 0 ? (
              data.map((overview, index) => (
                <TableRow key={index}>
                  <TableCell sx={tableBodyCellSx}>
                    <Typography sx={tableBodyTypoSx}>
                      {overview.grouprole}
                    </Typography>
                  </TableCell>

                  <TableCell sx={tableBodyCellSx}>
                    <Typography sx={tableBodyTypoSx}>
                      {overview.startDate}
                    </Typography>
                  </TableCell>

                  <TableCell sx={tableBodyCellSx}>
                    <Typography sx={tableBodyTypoSx}>
                      {overview.endDate}
                    </Typography>
                  </TableCell>
                  {isDesktop && (
                    <TableCell sx={tableBodyCellSx}>
                      <Typography sx={tableBodyTypoSx}>
                        {overview.timesheetSummaries?.length || 0}
                      </Typography>
                    </TableCell>
                  )}

                  <TableCell sx={tableBodyCellSx}>
                    <Typography sx={tableBodyTypoSx}>
                      {convertToHourMinute(overview.effectiveHours!)}
                    </Typography>
                  </TableCell>

                  <TableCell
                    sx={{
                      fontSize: { xs: '0.7rem', sm: '0.9rem', md: '1.0rem' },
                      padding: '16px 8px',
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: { xs: '0.7rem', sm: '0.9rem', md: '1.0rem' },
                        overflowWrap: 'break-word',
                        wordBreak: 'break-word',
                        color: 'silver',
                      }}
                    >
                      {convertToHourMinute(overview.totalHours!)}
                    </Typography>
                  </TableCell>

                  <TableCell sx={tableBodyCellSx}>
                    <Tooltip title="Abrechnungsübersicht">
                      <IconButton
                        color="primary"
                        onClick={() => handleRowClick(overview)}
                      >
                        <RemoveRedEyeIcon />
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={7} align="center">
                  <Typography>Keine Daten verfügbar</Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default AccountingTable;
