import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useCallback,
} from 'react';

// Define the context shape
interface DrawerContextType {
  isDrawerOpen: boolean;
  toggleDrawer: () => void;

  pressedIndexRow: number;
  setPressedIndexRow: React.Dispatch<React.SetStateAction<number>>;
}

// Create the context with a default value (it will be overwritten by the provider)
const DrawerContext = createContext<DrawerContextType | undefined>(undefined);

// Create the provider component
const DrawerProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);
  const [pressedIndexRow, setPressedIndexRow] = useState(0);

  // Function to toggle the drawer open/close state
  const toggleDrawer = useCallback(() => {
    setIsDrawerOpen((prevState) => !prevState);
  }, []);

  return (
    <DrawerContext.Provider
      value={{
        isDrawerOpen,
        toggleDrawer,
        pressedIndexRow,
        setPressedIndexRow,
      }}
    >
      {children}
    </DrawerContext.Provider>
  );
};

// Create a custom hook to use the DrawerContext
const useDrawer = (): DrawerContextType => {
  const context = useContext(DrawerContext);
  if (context === undefined) {
    throw new Error('useDrawer must be used within a DrawerProvider');
  }
  return context;
};

export { DrawerProvider, useDrawer };
