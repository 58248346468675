import React from 'react';
import { IconButton, Paper, Typography } from '@mui/material';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { useWindowWidth } from '../../contexts/WindowWidthContext';
import backgroundImage from '../../images/sapling.jpg';
import { useTimesheet } from '../../contexts/TimesheetContext';
import dayjs from 'dayjs';
import { getMonthAndYearInGerman } from './tool';

const TrackMonthlyBanner = () => {
  const { isType } = useWindowWidth();
  const {
    timesheetSummary,
    isMonthly,
    setTimesheetStartDate,
    setTimesheetEndDate,
  } = useTimesheet();

  const formattedStartDate = dayjs(timesheetSummary?.startDate).format('DD.MM');
  const formattedEndDate = dayjs(timesheetSummary?.endDate).format('DD.MM');
  const monthAndYearString = getMonthAndYearInGerman(
    new Date(timesheetSummary?.startDate || dayjs().format()),
  );

  const changeMonth = (direction: 'prev' | 'next') => {
    if (timesheetSummary) {
      const currentStartDate = dayjs(timesheetSummary.startDate);
      let newStartDate: dayjs.Dayjs;
      let newEndDate: dayjs.Dayjs;

      if (direction === 'prev') {
        newStartDate = currentStartDate.subtract(1, 'month').startOf('month');
        newEndDate = newStartDate.endOf('month');
      } else {
        newStartDate = currentStartDate.add(1, 'month').startOf('month');
        newEndDate = newStartDate.endOf('month');
      }

      setTimesheetStartDate(newStartDate.toDate());
      setTimesheetEndDate(newEndDate.toDate());
    }
  };

  return (
    <Paper
      style={{
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: 'cover',
        margin: '20px',
        borderRadius: '24px',
        width: '80%',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)', // subtle shadow
      }}
      className="main"
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          textAlign: 'center',
        }}
      >
        <IconButton
          onClick={() => changeMonth('prev')}
          sx={{
            color: '#fff', // Brighter color for better visibility
            fontSize: !isType('desktop') ? '40px' : '80px', // Increased size
          }}
        >
          <KeyboardArrowLeftIcon fontSize="inherit" />
        </IconButton>

        <Typography
          variant={!isType('desktop') ? 'h5' : 'h3'} // Increased size for better visibility
          style={{
            fontWeight: 'bold',
            color: '#fff', // Brighter text color
            textShadow: '2px 2px 4px rgba(0, 0, 0, 0.6)', // Adds shadow for better contrast
          }}
        >
          {isMonthly
            ? monthAndYearString
            : formattedStartDate + ' - ' + formattedEndDate}
        </Typography>

        <IconButton
          onClick={() => changeMonth('next')}
          sx={{
            color: '#fff', // Brighter color for better visibility
            fontSize: !isType('desktop') ? '40px' : '80px', // Increased size
          }}
        >
          <KeyboardArrowRightIcon fontSize="inherit" />
        </IconButton>
      </div>
    </Paper>
  );
};

export default TrackMonthlyBanner;
