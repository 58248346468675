import { Alert } from '@mui/material';
import React from 'react';

const AlertComponent = () => {
  return (
    <Alert
      severity="error"
      sx={{
        margin: '20px',
        marginBottom: '2rem',
        fontSize: '1.2rem',
        padding: '1rem 2rem',
        borderRadius: '8px',
        backgroundColor: '#f44336',
        color: '#ffffff',
        display: 'flex',
        alignItems: 'center',
        '& .MuiAlert-icon': {
          fontSize: '2.5rem',
          color: '#ffffff',
          marginRight: '1rem',
        },
        '& .MuiAlert-message': {
          fontSize: { xs: '1rem', sm: '1.1rem', md: '1.2rem' },
          overflow: 'hidden',
        },
      }}
    >
      Du hast keine Berechtigungen! Um Sapling nutzen zu können, musst du von HR
      oder Paul erst freigeschaltet werden.
    </Alert>
  );
};

export default AlertComponent;
