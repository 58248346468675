import React, { FC, useEffect, useState } from 'react';
import {
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
  Box,
  Select,
  MenuItem,
  SelectChangeEvent,
  FormControl,
  styled,
} from '@mui/material';
import { User, UserInvitation, UserService } from '../../api';
import UserAvatar from '../UserAvatar';
import { useSnackbar } from '../../contexts/SnackbarProvider';

interface UserEditPopupProps {
  user: User;
  inviteMode: boolean;
  open: boolean;
  onClose: () => void;
  onSave: (updatedUser: User) => void;
  onDelete: (user: User) => void;
}

const provinces = {
  'DE-BW': 'Baden-Württemberg',
  'DE-BY': 'Bayern',
  'DE-BE': 'Berlin',
  'DE-BB': 'Brandenburg',
  'DE-HB': 'Bremen',
  'DE-HH': 'Hamburg',
  'DE-HE': 'Hessen',
  'DE-NI': 'Niedersachsen',
  'DE-MV': 'Mecklenburg-Vorpommern',
  'DE-NW': 'Nordrhein-Westfalen',
  'DE-RP': 'Rheinland-Pfalz',
  'DE-SL': 'Saarland',
  'DE-SN': 'Sachsen',
  'DE-ST': 'Sachsen-Anhalt',
  'DE-SH': 'Schleswig-Holstein',
  'DE-TH': 'Thüringen',
};
const UnderlinedText = styled('span')(({ theme }) => ({
  position: 'relative',
  display: 'inline-block',
  '&::after': {
    content: '""',
    position: 'absolute',
    left: 0,
    bottom: -2, // Position the underline just below the text
    width: '100%',
    height: '4px', // Thickness of the underline
    backgroundColor: theme.palette.primary.main, // Primary color
    borderRadius: '2px', // Rounded corners
  },
}));
const UserEditPopup: FC<UserEditPopupProps> = ({
  user,
  inviteMode,
  open,
  onClose,
  onSave,
  onDelete,
}) => {
  const { showSnackbar } = useSnackbar();

  const [editableUser, setEditableUser] = useState<User>({
    ...user,
    province: user.province ? user.province : 'DE-HE',
    allowedWorkingHours: {
      nightAllowed: user.allowedWorkingHours?.nightAllowed ?? false,
      weekendAllowed: user.allowedWorkingHours?.weekendAllowed ?? false,
      holidayAllowed: user.allowedWorkingHours?.holidayAllowed ?? false,
      weekMax: user.allowedWorkingHours?.weekMax ?? 0,
    },
  });

  const [roleOptions, setRoleOptions] = useState<string[]>([]);
  useEffect(() => {
    UserService.getAllRoles().then((x) => setRoleOptions(x));
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setEditableUser({ ...editableUser, [name]: value });
  };

  const handleProvinceChange = (event: SelectChangeEvent<string>) => {
    setEditableUser({ ...editableUser, province: event.target.value });
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setEditableUser((prevUser) => ({
      ...prevUser,
      allowedWorkingHours: {
        ...prevUser.allowedWorkingHours!,
        [name]: checked,
      },
    }));
  };

  const handleWeekMaxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setEditableUser((prevUser) => ({
      ...prevUser,
      allowedWorkingHours: {
        ...prevUser.allowedWorkingHours!,
        weekMax: parseInt(value, 10) || 0,
      },
    }));
  };

  const handleRoleToggle = (role: string) => {
    setEditableUser((prevUser) => {
      const roles = prevUser.roles.includes(role)
        ? prevUser.roles.filter((r) => r !== role)
        : [...prevUser.roles, role];
      return { ...prevUser, roles };
    });
  };

  // New inviteUser function
  const inviteUser = () => {
    const invitation: UserInvitation = {
      mail: editableUser.mail,
      roles: editableUser.roles,
      province: editableUser.province ? editableUser.province : 'DE-HE',
      allowedWorkingHours: {
        nightAllowed: editableUser.allowedWorkingHours?.nightAllowed ?? false,
        weekendAllowed:
          editableUser.allowedWorkingHours?.weekendAllowed ?? false,
        holidayAllowed:
          editableUser.allowedWorkingHours?.holidayAllowed ?? false,
        weekMax: editableUser.allowedWorkingHours?.weekMax ?? 20,
      },
    };

    UserService.inviteUser(invitation)
      .then(() => {
        showSnackbar('Nutzer eingeladen', 'success');
        onClose();
      })
      .catch((error) => {
        showSnackbar('Error updating user:', 'error', error);
      });
  };

  const handleSave = () => {
    UserService.updateUser(editableUser.uuid!, editableUser)
      .then((updatedUser) => {
        showSnackbar('Saved changes', 'success');
        onSave(updatedUser);
        onClose();
      })
      .catch((error) => {
        showSnackbar('Error updating user:', 'error', error);
      });
  };

  const handleDelete = () => {
    if (
      window.confirm(
        `Sicher, dass du den Nutzer ${editableUser.firstName} ${editableUser.lastName} löschen möchtest?`,
      )
    ) {
      UserService.deleteUser(editableUser.uuid!)
        .then(() => {
          console.log('delete');
          onDelete(editableUser);
          onClose();
        })
        .catch((error) => {
          console.error('Error deleting user:', error);
        });
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: '16px',
          }}
        >
          <UserAvatar
            user={
              inviteMode ? { ...editableUser, firstName: '+' } : editableUser
            }
            style={{ width: 80, height: 80, marginRight: '16px' }}
          />
          <Typography variant="h6">
            {!inviteMode && (
              <UnderlinedText>
                {editableUser.firstName} {editableUser.lastName}
              </UnderlinedText>
            )}
            {inviteMode && <UnderlinedText>Nutzer einladen</UnderlinedText>}
          </Typography>
        </div>
        <TextField
          margin="dense"
          label="Vorname"
          name="firstName"
          value={editableUser.firstName}
          onChange={handleInputChange}
          fullWidth
          disabled={inviteMode}
        />
        <TextField
          margin="dense"
          label="Nachname"
          name="lastName"
          value={editableUser.lastName}
          onChange={handleInputChange}
          fullWidth
          disabled={inviteMode}
        />
        <TextField
          margin="dense"
          label="Email"
          name="mail"
          value={editableUser.mail}
          onChange={handleInputChange}
          fullWidth
        />
        <FormControl fullWidth sx={{ marginTop: '8px' }}>
          <Select
            labelId="demo-simple-select-label"
            margin="dense"
            name="province"
            value={editableUser.province || 'DE-HE'}
            onChange={handleProvinceChange}
            fullWidth
            displayEmpty
          >
            <MenuItem value="" disabled>
              Bundesland wählen
            </MenuItem>
            {Object.entries(provinces).map(([shortCode, provinceName]) => (
              <MenuItem key={provinceName} value={shortCode}>
                {provinceName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Box sx={{ marginTop: '16px' }}>
          <Typography variant="subtitle1">
            Darf an folgenden Zeiten arbeiten:
          </Typography>
          <FormControlLabel
            control={
              <Checkbox
                checked={editableUser.allowedWorkingHours!.nightAllowed}
                onChange={handleCheckboxChange}
                name="nightAllowed"
              />
            }
            label="Nachts"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={editableUser.allowedWorkingHours!.weekendAllowed}
                onChange={handleCheckboxChange}
                name="weekendAllowed"
              />
            }
            label="Wochenende"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={editableUser.allowedWorkingHours!.holidayAllowed}
                onChange={handleCheckboxChange}
                name="holidayAllowed"
              />
            }
            label="Feiertage"
          />
          <TextField
            margin="dense"
            label="Stunden pro Woche"
            type="number"
            value={editableUser.allowedWorkingHours!.weekMax}
            onChange={handleWeekMaxChange}
            fullWidth
          />
          <TextField
            margin="dense"
            label="Offener Arbeitszeitübertrag (min)"
            type="number"
            value={editableUser.pendingMinutes || 0}
            fullWidth
            disabled={true}
          />
        </Box>
        <Box sx={{ marginTop: '16px' }}>
          <Typography variant="subtitle1">Rollen:</Typography>
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              gap: '8px',
              marginTop: '8px',
            }}
          >
            {roleOptions.map(
              (role) =>
                role !== 'DEFAULT' && (
                  <Chip
                    key={role}
                    label={role}
                    color={
                      editableUser.roles.includes(role) ? 'success' : 'default'
                    }
                    onClick={() => handleRoleToggle(role)}
                    style={{
                      backgroundColor: editableUser.roles.includes(role)
                        ? 'green'
                        : 'grey',
                      color: 'white',
                      cursor: 'pointer',
                    }}
                  />
                ),
            )}
          </div>
        </Box>
      </DialogContent>
      <DialogActions>
        {!inviteMode && (
          <Button onClick={handleDelete} color="error">
            Löschen
          </Button>
        )}
        <Button onClick={onClose} color="secondary">
          Abbrechen
        </Button>
        {!inviteMode && (
          <Button onClick={handleSave} color="primary">
            Speichern
          </Button>
        )}
        {inviteMode && (
          <Button onClick={inviteUser} color="primary">
            Einladen
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default UserEditPopup;
