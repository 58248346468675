import React from 'react';
import Section from './Section';
import { Typography } from '@mui/material';
import overviewDemoSrc from '../../../images/demo_abrechnung.png';

const TimeTrackingDemo = ({
  index,
  buttonDisabled,
}: {
  index: number;
  buttonDisabled: boolean;
}) => {
  return (
    <Section
      index={index}
      title="Stundenübersicht"
      madeFor="HR & Finance"
      buttonLink="/hrprompt"
      imageSrc={overviewDemoSrc}
      buttonDisabled={buttonDisabled}
    >
      <Typography
        variant="body1"
        sx={{
          fontSize: { xs: '1rem', sm: '1.1rem', md: '1.2rem' },
          marginBottom: '1rem',
        }}
      >
        Mit nur einem Klick kann die HR- oder Finanzabteilung alle Stunden einer
        ausgewählten Gruppe für einen vergangenen oder aktuellen Zeitraum
        einsehen. Ein weiterer Klick auf einen einzelnen Nutzer und die
        Timesheets können für diesen Nutzer im Detail betrachtet werden. Mittels
        eines Klickes kann so außerdem eine Abrechnung für einen beliebigen
        Zeitraum vollzogen werden.
      </Typography>
    </Section>
  );
};

export default TimeTrackingDemo;
