import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import DangerousIcon from '@mui/icons-material/Dangerous';
import PaidIcon from '@mui/icons-material/Paid';
import HelpIcon from '@mui/icons-material/Help';
import LockIcon from '@mui/icons-material/Lock';
import { StatusEnum } from '../../api';

// Define the StatusIconMap type
interface StatusIconMap {
  [key: string]: {
    icon: React.ReactElement<JSX.Element>;
  };
}

// Define the statusIconMap with tooltips
const statusIconMap: StatusIconMap = {
  [StatusEnum.GOOD]: {
    icon: (
      <Tooltip title="Bereit zum Abrechnen">
        <CheckCircleIcon color="success" />
      </Tooltip>
    ),
  },
  [StatusEnum.ERROR]: {
    icon: (
      <Tooltip title="FEHLER">
        <DangerousIcon color="error" />
      </Tooltip>
    ),
  },
  [StatusEnum.WARNING]: {
    icon: (
      <Tooltip title="Timesheet enthält Fehler">
        <ErrorIcon color="warning" />
      </Tooltip>
    ),
  },
  [StatusEnum.APPROVED]: {
    icon: (
      <Tooltip title="Timesheet ist abgerechnet">
        <PaidIcon color="success" />
      </Tooltip>
    ),
  },
  [StatusEnum.UNKNOWN]: {
    icon: (
      <Tooltip title="Timesheet ist nicht vollständig ausgefüllt">
        <HelpIcon color="info" />
      </Tooltip>
    ),
  },
  [StatusEnum.LOCKED]: {
    icon: (
      <Tooltip title="Timesheet ist temporär blockiert">
        <LockIcon color="warning" />
      </Tooltip>
    ),
  },
};

interface StatusIconProps {
  status: StatusEnum;
}

const StatusIconComponent: React.FC<StatusIconProps> = ({ status }) => {
  const iconEntry = statusIconMap[status];
  if (!iconEntry) {
    return null; // Or handle unknown status cases
  }

  return iconEntry.icon;
};

export default StatusIconComponent;
