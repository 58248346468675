import { Avatar, Box, Grid, Typography } from '@mui/material';
import React from 'react';

import ms from '../../images/faces/ms.jpg';
import pa from '../../images/faces/pa.jpg';

const developers = [
  // Active devs at the front (I miss you martin please come back <3)
  {
    name: 'Paul Adelmann',
    email: 'paul.adelmann@nterra.com',
    pic: pa,
    note: 'aktiv',
  },
  {
    name: 'Martin Stemmer',
    email: 'martin-stemmer@t-online.de',
    pic: ms,
    note: 'schwer vermisst <3 (anderweitig beschäftigt)',
  },
];

const DeveloperOverview = () => {
  return (
    <>
      <Typography
        variant="h4"
        component="h2"
        gutterBottom
        sx={{
          fontWeight: 'bold',
          marginBottom: '2rem',
          marginTop: '2rem',
          fontSize: { xs: '1.75rem', sm: '2rem', md: '2.5rem' }, // Responsive font sizes
        }}
      >
        Unser Team
      </Typography>

      <Typography
        variant="body1"
        sx={{
          fontSize: { xs: '1rem', sm: '1.1rem', md: '1.2rem' }, // Responsive font sizes
          marginBottom: '1rem',
        }}
      >
        Mario und Luigi haben dieses Projekt entwickelt. Bei Fragen bezüglich
        dieses Projektes können diese Entwickler angeschrieben werden.
      </Typography>
      <Grid container spacing={4}>
        {developers.map((developer, index) => (
          <Grid item xs={12} md={6} key={index}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                padding: '1rem',
                border: '1px solid #ddd',
                borderRadius: '8px',
                backdropFilter: 'blur(2px)', // Apply blur effect
                backgroundColor: 'rgba(255, 255, 255, 0.5)', // Semi-transparent background
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Optional: Add shadow for better contrast
                height: '150px', // Set a fixed height for all items
                boxSizing: 'border-box', // Ensure padding and border are included in the total height
                width: '100%', // Ensure the Box takes full width of Grid item
              }}
            >
              <Avatar
                src={developer.pic}
                alt={developer.name}
                sx={{ width: 80, height: 80, marginRight: '1rem' }}
              />
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: 'bold',
                    marginBottom: '0.5rem',
                    marginTop: '1rem',
                  }}
                >
                  {developer.name}
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  sx={{
                    overflowWrap: 'break-word',
                    wordBreak: 'break-word',
                  }}
                >
                  {developer.email}
                </Typography>
                <Typography
                  variant="caption"
                  display="block"
                  sx={{
                    marginBottom: '1rem',
                    color: 'grey.600',
                  }}
                >
                  {developer.note}
                </Typography>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default DeveloperOverview;
