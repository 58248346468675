import { Button, Grid, Typography } from '@mui/material';
import React, { FC, ReactNode } from 'react';
import { Link } from 'react-router-dom';

interface SectionProps {
  children?: ReactNode;
  index: number;
  title: string;
  madeFor: string;
  imageSrc: string;
  buttonLink: string;
  buttonDisabled: boolean;
}

const Section: FC<SectionProps> = ({
  children,
  index,
  title,
  madeFor,
  imageSrc,
  buttonLink,
  buttonDisabled,
}) => {
  return (
    <Grid
      container
      spacing={4}
      alignItems="flex-start"
      sx={{ marginBottom: '4rem' }}
    >
      <Grid item xs={12} md={6} order={{ xs: 1, md: (index % 2) + 1 }}>
        <Typography
          variant="h5"
          component="h2"
          sx={{
            fontWeight: 'bold',
            marginBottom: '1rem',
            fontSize: { xs: '1.5rem', sm: '1.75rem', md: '2rem' },
          }}
        >
          {title}
        </Typography>
        {children}
        <Typography
          variant="caption"
          display="block"
          sx={{
            color: 'grey.600',
            marginTop: '1rem',
            fontSize: { xs: '0.75rem', sm: '0.85rem', md: '0.9rem' },
          }}
        >
          Für: {madeFor}
        </Typography>
        <Button
          variant="contained"
          component={Link}
          to={buttonLink}
          color="primary"
          sx={{ marginTop: '2rem' }}
          disabled={buttonDisabled}
        >
          Ausprobieren
        </Button>
      </Grid>
      <Grid item xs={12} md={6} order={{ xs: 2, md: ((index + 1) % 2) + 1 }}>
        <img
          src={imageSrc}
          alt="Demo"
          style={{ width: '100%', borderRadius: '8px' }}
        />
      </Grid>
    </Grid>
  );
};

export default Section;
