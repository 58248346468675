import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
  Box,
  Link,
} from '@mui/material';

const COOKIE_CONSENT_KEY = 'saplingCookieConsentGiven';

export let consentGiven = localStorage.getItem(COOKIE_CONSENT_KEY) === 'true';

interface CookieConsentProps {
  onConsent?: () => void;
}

const CookieConsent: React.FC<CookieConsentProps> = () => {
  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    const consentGiven = localStorage.getItem(COOKIE_CONSENT_KEY);
    if (!consentGiven) {
      setOpen(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem(COOKIE_CONSENT_KEY, 'true');
    setOpen(false);
    consentGiven = true;
    window.location.reload();
  };

  const handleDeny = () => {
    localStorage.removeItem(COOKIE_CONSENT_KEY);
    setOpen(false);
    consentGiven = false;
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="cookie-consent-dialog-title"
      aria-describedby="cookie-consent-dialog-description"
      fullWidth
      maxWidth="sm"
      PaperProps={{
        sx: {
          borderRadius: '12px',
          backgroundColor: '#fff',
          boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
        },
      }}
    >
      <DialogTitle
        id="cookie-consent-dialog-title"
        sx={{ display: 'flex', alignItems: 'center', fontWeight: 'bold' }}
      >
        Keks gefällig?
      </DialogTitle>
      <DialogContent>
        <Box
          sx={{ textAlign: 'center', padding: '2rem', paddingBottom: '0rem' }}
        >
          <Typography
            id="cookie-consent-dialog-description"
            variant="body1"
            sx={{ mb: 2 }}
          >
            Sapling funktioniert nicht ohne Cookies.
            <br />
            <br />
            Alle verwendeten Cookies sind essentiell zur Funktion der Seite und
            daher technisch notwendig. Zum Login wird OAuth via Google
            angeboten, daher werden von Google verwaltete Cookies gesetzt. Der
            lokale Webseitenspeicher wird genutzt, um das eigene Profilbild oder
            Tokens zu cachen. Zusätzlich speichert ein Cookie die Bestätigung
            dieses Dialoges. Diese Cookies sind notwendig, um die
            Grundfunktionen von Sapling nutzen zu können. <br />
            <br />
            Allerdings ist es möglich, sich einfach ein wenig umzusehen und
            selbst die benötigten Cookies abzulehnen. Um Sapling ordentlich zu
            nutzen, müssen die technisch notwendigen Cookies allerdings
            akzeptiert werden.
          </Typography>
          <Typography variant="body2" sx={{ mb: 2 }}>
            Spannendes gibts auch in der{' '}
            <Link href="https://www.nterra.com/datenschutz/" color="primary">
              nterra Datenschutzerklärung{' '}
            </Link>
            zu lesen.
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions sx={{ justifyContent: 'center', pb: 2 }}>
        <Button
          onClick={handleAccept}
          variant="contained"
          color="primary"
          sx={{
            textTransform: 'none',
            borderRadius: '20px',
            padding: '10px 20px',
          }}
        >
          Technisch notwendige Cookies akzeptieren
        </Button>
        <Button
          onClick={handleDeny}
          variant="contained"
          color="secondary"
          sx={{
            textTransform: 'none',
            borderRadius: '20px',
            padding: '10px 20px',
          }}
        >
          Ich will nur schauen
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CookieConsent;
