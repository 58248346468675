import React, { memo } from 'react';
import Typography from '@mui/material/Typography';
import '../../Timesheet.css';
import { formatDateInGerman } from '../../tool';
const DateCell = ({ date }: { date: Date }) => {
  return (
    <td
      className="column-divider datum"
      style={{ textAlign: 'center', width: '20px', backgroundColor: 'white' }}
    >
      <Typography style={{ fontWeight: 'bold', width: '100%' }}>
        {formatDateInGerman(date).weekday}
      </Typography>
    </td>
  );
};

export default memo(DateCell);
