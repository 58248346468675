import { createTheme } from '@mui/material/styles';

// Define your custom colors
const nterraBlue = '#008aff';
const nterraOrange = '#ffb642';
const nterraGreen = '#1ae2a0';
const nterraRed = '#ff5160';
// Extended Shades and Tints
const nterraBlueLight = '#66b3ff'; // A lighter blue shade
const nterraBlueDark = '#0061cc'; // A darker blue shade
const nterraOrangeLight = '#ffd27f'; // A lighter orange shade
const nterraOrangeDark = '#e69400'; // A darker orange shade
const nterraGreenLight = '#66f0c4'; // A lighter green shade
const nterraGreenDark = '#00b374'; // A darker green shade
const nterraRedLight = '#ff8290'; // A lighter red shade
const nterraRedDark = '#cc3d4d'; // A darker red shade

// Complementary Colors (optional, for variety and accenting)
const nterraPurple = '#7e57c2'; // Complementary purple
const nterraYellow = '#ffeb3b'; // Bright yellow for highlights
const nterraTeal = '#009688'; // Teal for secondary accents
const nterraGrey = '#607d8b'; // Neutral grey for backgrounds or text

declare module '@mui/material/styles' {
  interface Palette {
    custom: {
      blue: string;
      blueLight: string;
      blueDark: string;
      orange: string;
      orangeLight: string;
      orangeDark: string;
      green: string;
      greenLight: string;
      greenDark: string;
      red: string;
      redLight: string;
      redDark: string;
      purple: string;
      yellow: string;
      teal: string;
      grey: string;
    };
  }
  interface PaletteOptions {
    custom?: {
      blue?: string;
      blueLight?: string;
      blueDark?: string;
      orange?: string;
      orangeLight?: string;
      orangeDark?: string;
      green?: string;
      greenLight?: string;
      greenDark?: string;
      red?: string;
      redLight?: string;
      redDark?: string;
      purple?: string;
      yellow?: string;
      teal?: string;
      grey?: string;
    };
  }
}

// Define your theme
const theme = createTheme({
  typography: {
    fontFamily: `"Readex Pro", "Roboto", "Helvetica", "Arial", sans-serif`,
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },
  palette: {
    primary: {
      main: nterraGreen, // Use nterraBlue as the primary color
    },
    secondary: {
      main: nterraOrange, // Use nterraOrange as the secondary color
    },
    error: {
      main: nterraRed,
    },
    warning: {
      main: nterraOrange,
    },
    success: {
      main: nterraGreen,
    },
    custom: {
      blue: nterraBlue,
      blueLight: nterraBlueLight,
      blueDark: nterraBlueDark,
      orange: nterraOrange,
      orangeLight: nterraOrangeLight,
      orangeDark: nterraOrangeDark,
      green: nterraGreen,
      greenLight: nterraGreenLight,
      greenDark: nterraGreenDark,
      red: nterraRed,
      redLight: nterraRedLight,
      redDark: nterraRedDark,
      purple: nterraPurple,
      yellow: nterraYellow,
      teal: nterraTeal,
      grey: nterraGrey,
    },
  },
});

export default theme;
