import React, { memo } from 'react';
import '../../Timesheet.css';
import { Typography } from '@mui/material';

const TextCell = ({ text }: { text: string }) => {
  return (
    <td colSpan={3} className="information">
      <Typography style={{ fontWeight: 'bold' }}>{text}</Typography>
    </td>
  );
};

export default memo(TextCell);
